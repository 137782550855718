import { DokumentiService } from './dokumenti.service';
import { KalkulacijaService } from './kalkulacija.service';
import { ArtiklService } from './artikl.service';
import { PrenosnicaService } from './prenosnica.service';
import { NivelacijaService } from './nivelacija.service';
import { IzvestaiService } from './izvestai.service';
import { UvozProdavnicaService } from './uvoz-prodavnica.service';

export const PRODAVNICA_SERVICE_DECLARATIONS: any[] = [
    ArtiklService,
    DokumentiService,
    KalkulacijaService,
    PrenosnicaService,
    NivelacijaService,
    IzvestaiService,
    UvozProdavnicaService
];
