import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDokumentZaDropdown } from '../../models/prodavnica/dialogs';

@Injectable()
export class DokumentiService {

    constructor(public httpClient: HttpClient) {}

    getKalkulacii(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/ProdavnicaApi/Dokumenti/GetKalkulacii`);
    }

    getNivelacii(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/ProdavnicaApi/Dokumenti/GetNivelacii`);
    }

    getPrenosniciOd(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/ProdavnicaApi/Dokumenti/GetPrenosniciOd`);
    }

    getPrenosniciVo(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/ProdavnicaApi/Dokumenti/GetPrenosniciVo`);
    }

    getUvozi(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/ProdavnicaApi/Dokumenti/GetUvozi`);
    }

}
