import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUvozVoProdavnica } from '../../models/prodavnica/uvoz-prodavnica';
import { IBrzVnesUvozProdavnica } from '../../models/prodavnica/uvoz-prodavnica';
import { VeliciniBukviPostModel } from '../../models/prodavnica/brz-vnes';
import { VeliciniBrojkiPostModel } from '../../models/prodavnica/brz-vnes';

@Injectable()
export class UvozProdavnicaService {

    constructor(public httpClient: HttpClient) {}

    getDokument(uvozId: string, kalkulacijaId: string): Observable<IUvozVoProdavnica> {
        return this.httpClient.get<IUvozVoProdavnica>(
            `/ProdavnicaApi/UvozProdavnica/GetDokument/${uvozId}/${kalkulacijaId}`);
    }

    postBukvi(model: VeliciniBukviPostModel): Observable<IBrzVnesUvozProdavnica> {
        return this.httpClient.post<IBrzVnesUvozProdavnica>(`/ProdavnicaApi/UvozProdavnica/VeliciniBukvi`, model);
    }

    postBrojki(model: VeliciniBrojkiPostModel): Observable<IBrzVnesUvozProdavnica> {
        return this.httpClient.post<IBrzVnesUvozProdavnica>(`/ProdavnicaApi/UvozProdavnica/VeliciniBrojki`, model);
    }

    deleteStavka(stavkaId: number, kalkulacijaId: number) {
        return this.httpClient.get(`/ProdavnicaApi/UvozProdavnica/DeleteStavka/${stavkaId}/${kalkulacijaId}`);
    }
}
